import classes from "./CompanyHeading.module.css";
import logo from "../assets/logo.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const CompanyHeading = () => {
  const { ref, inView } = useInView();

  return (
    <div className={classes["company_heading_div"]} ref={ref}>
      <div className={classes["logo_div"]}>
        <img className={classes["logo"]} src={logo} alt="d" />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
        transition={{ duration: 0.5 }}
      >
        <div className={classes["motto_div"]}>
          <p className={classes["quote"]}>
            "Jeżeli idzie o doradców niech będzie jeden z tysiąca"
          </p>
          <p className={classes["name"]}>
            Kancelaria Radców Prawnych Nieszczerzewski Spółka Komandytowa
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default CompanyHeading;
