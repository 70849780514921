import classes from "./Services.module.css";
import balance from "../assets/balance_white.png";
import tax from "../assets/tax_white.png";
import high from "../assets/high_white.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Services = () => {
  return (
    <div className={classes["services_div"]}>
      <div className={classes["services_list"]}>
        <Service service_name="Prawo cywilne" img={balance} />
        <Service service_name="Podatki" img={tax} />
        <Service service_name="Procesy sądowe" img={high} />
      </div>
    </div>
  );
};

const Service = (props) => {
  const { ref, inView } = useInView();

  return (
    <div ref={ref}>
      <motion.div
        initial={{ opacity: 0, scale: 0 }} // Start with opacity 0 and scale 0
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }} // Animate opacity and scale based on inView value
        transition={{ duration: 1 }}
      >
        <div className={classes["service"]}>
          <img className={classes["img"]} src={props.img} alt="" />
          <p className={classes["service_name"]}>{props.service_name}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default Services;
