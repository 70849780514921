import classes from "./Footer.module.css";

const Footer = (props) => {
  const handleGithubClick = () => {
    window.location.href = "https://github.com/MichalZdanuk";
  };
  return (
    <div className={classes["footer_div"]}>
      <div className={classes["company_data_div"]}>
        {props.company_info}
        <hr />
        {props.company_info_2}
      </div>
      <div className={classes["copyright_div"]} onClick={handleGithubClick}>
        @copyright Michał Żdanuk
      </div>
    </div>
  );
};

export default Footer;
