import classes from "./Location.module.css";
import map from "../assets/map.png";
import building1 from "../assets/building1.jpg";
import building2 from "../assets/building2.jpg";

const Location = () => {
  const handleMapClick = () => {
    window.location.href = "https://goo.gl/maps/YM4JZZj5jGH9upBh9";
  };

  return (
    <div className={classes["location_div"]}>
      <div>
        <h2 className={classes["location_heading"]}>LOKALIZACJA - BIAŁYSTOK</h2>
      </div>
      <div className={classes["images_div"]}>
        <div className={classes["building_img_div"]}>
          <img className={classes["building"]} src={building1} alt="" />
          <img className={classes["building"]} src={building2} alt="" />
        </div>
        <div>
          <img
            className={classes["map"]}
            src={map}
            alt="mapa"
            onClick={handleMapClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Location;
