import classes from "./CompanyInfo.module.css";
import mail from "../assets/mail.png";
import telephone from "../assets/telephone.png";
import home from "../assets/home.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const CompanyInfo = () => {
  return (
    <div className={classes["company_info_div"]}>
      <Info img={mail} info="kancelaria.bialystok@sp-partnerska.info" />
      <Info img={telephone} info="606 494 624" info2="85 74 82 052" />
      <Info
        img={home}
        info="15-094 Białystok, ul.M.Skłodowskiej-Curie 3, lok 29"
      />
    </div>
  );
};

const Info = (props) => {
  const { ref, inView } = useInView();

  return (
    <div ref={ref}>
      <motion.div
        initial={{ opacity: 0, scale: 0 }} // Start with opacity 0 and scale 0
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }} // Animate opacity and scale based on inView value
        transition={{ duration: 1.1 }}
      >
        <div className={classes["info"]}>
          <img className={classes["contact_icon"]} src={props.img} alt="" />
          {props.info2 !== "" ? (
            <div>
              <p className={classes["contact_text"]}>{props.info}</p>
              <p className={classes["contact_text"]}>{props.info2}</p>
            </div>
          ) : (
            <p className={classes["contact_text"]}>{props.info}</p>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default CompanyInfo;
