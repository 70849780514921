import classes from "./App.module.css";

import CompanyHeading from "./components/CompanyHeading";
import CompanyInfo from "./components/CompanyInfo";
import Location from "./components/Location";
import Services from "./components/Services";
import PersonalInfo from "./components/PersonalInfo";
import Footer from "./components/Footer";

import { company_info } from "./info";
import { company_info_2 } from "./info";
import { about_me } from "./info";
import { experience } from "./info";

function App() {
  return (
    <div className={classes["main_container"]}>
      <CompanyHeading />
      <CompanyInfo />
      <Location />
      <Services />
      <PersonalInfo about_me={about_me} experience={experience} />
      <Footer company_info={company_info} company_info_2={company_info_2} />
    </div>
  );
}

export default App;
