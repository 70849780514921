import classes from "./PersonalInfo.module.css";
import lawyer from "../assets/lawyer.png";
import {motion} from "framer-motion";
import { useInView } from "react-intersection-observer";

const PersonalInfo = (props) => {
  const {ref, inView} = useInView({
  });

  return (
    <div className={classes["personal_info_div"]} >
      
      <div className={classes["about_me_div"]} ref={ref}>
      <motion.div
  initial={{ opacity: 0, x: -500 }} // Start with opacity 0 and x position -100
  animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -500 }} // Animate opacity and x position based on inView value
  transition={{ duration: 1 }}
>
        <h2 className={classes["heading"]}>Życiorys</h2>
        <p className={classes["plain_text"]}>{props.about_me}</p>
        <h2 className={classes["heading"]}>Doświadczenie zawodowe</h2>
        <p className={classes["plain_text"]}>{props.experience}</p>
    </motion.div>
      </div>
      <div className={classes["img_div"]}>
        <img className={classes["img"]} src={lawyer} alt="" />
      </div>
    </div>
  );
};

export default PersonalInfo;
